var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-order__status" }, [
    _vm.statusCreated
      ? _c("div", { staticClass: "new-order__status-inner" }, [
          _vm._m(0),
          _c("div", { staticClass: "new-order__status-title" }, [
            _vm._v("Order booked!")
          ]),
          _c("div", { staticClass: "new-order__status-subtitle" }, [
            _vm._v("Your order has been book and waiting for approval.")
          ]),
          _c("div", { staticClass: "new-order__status-buttons" }, [
            _c(
              "button",
              { staticClass: "button", on: { click: _vm.viewDeliveries } },
              [_vm._v("View my orders")]
            ),
            !_vm.onlyPay
              ? _c(
                  "button",
                  {
                    staticClass: "button button--fill",
                    on: { click: _vm.newOrder }
                  },
                  [_vm._v("Book New Order")]
                )
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm.statusConfirm
      ? _c("div", { staticClass: "new-order__status-inner" }, [
          _vm._m(1),
          _c("div", { staticClass: "new-order__status-title" }, [
            _vm._v("Please confirm order")
          ]),
          _c("div", { staticClass: "new-order__status-subtitle" }),
          _c("div", { staticClass: "new-order__status-buttons" }, [
            _c(
              "button",
              {
                staticClass: "button button--green",
                on: { click: _vm.confirmPI }
              },
              [_vm._v("Confirm")]
            ),
            _c(
              "button",
              {
                staticClass: "button button--red",
                on: { click: _vm.cancelOrder }
              },
              [_vm._v("Cancel")]
            )
          ])
        ])
      : _vm._e(),
    _vm.statusCancel
      ? _c("div", { staticClass: "new-order__status-inner" }, [
          _vm._m(2),
          _c("div", { staticClass: "new-order__status-title" }, [
            _vm._v("Order Canceled")
          ]),
          _c("div", { staticClass: "new-order__status-subtitle" }),
          _c("div", { staticClass: "new-order__status-buttons" }, [
            _c(
              "button",
              { staticClass: "button", on: { click: _vm.viewDeliveries } },
              [_vm._v("View my orders")]
            ),
            !_vm.onlyPay
              ? _c(
                  "button",
                  {
                    staticClass: "button button--fill",
                    on: { click: _vm.newOrder }
                  },
                  [_vm._v("Book New Order")]
                )
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm.statusWaiting
      ? _c("div", { staticClass: "new-order__status-inner" }, [
          _vm._m(3),
          _c("div", { staticClass: "new-order__status-title" }, [
            _vm._v("Waiting for payment")
          ]),
          _c("div", { staticClass: "new-order__status-subtitle" }),
          _c("div", { staticClass: "new-order__status-buttons" }, [
            _c(
              "button",
              { staticClass: "button", on: { click: _vm.viewDeliveries } },
              [_vm._v("View my orders")]
            ),
            _c(
              "button",
              {
                staticClass: "button button--fill",
                on: { click: _vm.newOrder }
              },
              [_vm._v("Book New Order")]
            )
          ])
        ])
      : _vm._e(),
    _vm.statusError
      ? _c("div", { staticClass: "new-order__status-inner" }, [
          _vm._m(4),
          _c("div", { staticClass: "new-order__status-title" }, [
            _vm._v("Payment Error")
          ]),
          _c("div", { staticClass: "new-order__status-subtitle" }),
          _c("div", { staticClass: "new-order__status-buttons" }, [
            _c(
              "button",
              { staticClass: "button", on: { click: _vm.viewDeliveries } },
              [_vm._v("View my orders")]
            ),
            !_vm.onlyPay
              ? _c(
                  "button",
                  {
                    staticClass: "button button--fill",
                    on: { click: _vm.newOrder }
                  },
                  [_vm._v("Book New Order")]
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "new-order__status-icon new-order__status-icon--green" },
      [_c("i", { staticClass: "ri-checkbox-circle-fill" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "new-order__status-icon new-order__status-icon--orange" },
      [_c("i", { staticClass: "ri-indeterminate-circle-fill" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "new-order__status-icon new-order__status-icon--red" },
      [_c("i", { staticClass: "ri-close-circle-fill" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "new-order__status-icon new-order__status-icon--blue" },
      [_c("i", { staticClass: "ri-money-dollar-circle-fill" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "new-order__status-icon new-order__status-icon--red" },
      [_c("i", { staticClass: "ri-close-circle-fill" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }